import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { ChevronDoubleLeftIcon, ChevronRightIcon, HomeIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import noImage from '../images/profiles/noimage.png';

const CreatorListPage = ({ data, pageContext, location }) => {

  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const [searchQuery, setSearchQuery] = useState('');
  const creatorProfiles = data.allMarkdownRemark.nodes;
  const { currentPage, numPages, totalProfiles } = pageContext;
  const profilesPerPage = 10;
  
  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/profiles" : `/profiles/${currentPage - 1}`;
  const nextPage = `/profiles/${currentPage + 1}`;
  const pages = [
    { name: 'Profiles', href: '', current: true },
  ]

  const renderPagination = () => {
    let items = [];
    const maxPagesToShow = 4; // Adjust as needed
  
    // Adding the first page and previous arrow if not on the first page
    if (!isFirst) {
      items.push(
        <Link key="first" to="/profiles/" className="relative inline-flex items-center px-4 py-2 text-white ring-1  rounded-l-md ring-inset ring-gray-700 hover:bg-gray-700 focus:z-20 focus:outline-offset-0">
          <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      );
      items.push(
        <Link key="prev" to={prevPage} className="relative inline-flex items-center px-2 py-2 text-white ring-1 ring-inset ring-gray-700 hover:bg-gray-700 focus:z-20 focus:outline-offset-0">
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      );
    }
  
    // Calculate the range of pages to show
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(numPages, startPage + maxPagesToShow - 1);
  
    // Generate page number links
    for (let i = startPage; i <= endPage; i++) {
      const linkTo = i === 1 ? "/profiles/" : `/profiles/${i}`;
      items.push(
        <Link key={i} to={linkTo} className={currentPage === i ? "relative inline-flex items-center px-4 py-2 text-white ring-1 ring-inset ring-white hover:bg-gray-700 focus:z-20 focus:outline-offset-0" : "relative inline-flex items-center px-4 py-2 text-white ring-1 ring-inset ring-gray-700 hover:bg-gray-700 focus:z-20 focus:outline-offset-0"}>
          {i}
        </Link>
      );
    }
  
    // Ellipsis and last page if there's a gap between the last page and pages shown
    if (endPage < numPages) {
      if (endPage < numPages - 1) {
        items.push(<span key="ellipsis" className="hidden md:inline-flex relative items-center px-4 py-2 text-white ring-1 ring-inset ring-gray-700 hover:bg-gray-700 focus:z-20 focus:outline-offset-0">...</span>);
      }
      items.push(
        <Link key={numPages} to={`/profiles/${numPages}`} className="hidden md:inline-flex relative  items-center px-4 py-2 text-white ring-1 ring-inset ring-gray-700 hover:bg-gray-700 focus:z-20 focus:outline-offset-0">
          {numPages}
        </Link>
      );
    }
  
    // Adding the next arrow if not on the last page
    if (!isLast) {
      items.push(
        <Link key="next" to={nextPage} className="relative inline-flex items-center rounded-r-md px-2 py-2 text-white ring-1 ring-inset ring-gray-700 hover:bg-gray-700 focus:z-20 focus:outline-offset-0">
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      );
    }
  
    return <div className="flex justify-center space-x-1">{items}</div>;
  };
  
  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Search Production Crew Profiles" />
      
      
      <div className="bg-gray-900 p-5 mx-1 md:py-5">
        <div className="container mx-auto">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-3">
              <li>
                <div>
                  <a href="/" className="text-gray-400 hover:text-white">
                    <HomeIcon className="h-3 w-3 flex-shrink-0" aria-hidden="true" />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-3 w-3 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <a
                      href={page.href}
                      className="ml-4 text-xs font-medium text-gray-500 hover:text-white"
                      aria-current={page.current ? 'page' : undefined}
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>

      <div className="">
        <div className="bg-gray-900 w-full px-5">
          <div className="container mx-auto transform divide-y divide-gray-700 overflow-hidden rounded-xl bg-gray-800 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all focus:outline-none">
            {/* <div className="bg-gray-900 mx-1">
              <div className="container mx-auto">
                <div className="bg-gray-800 shadow-2xl py-5">
                  <div className="relative px-5">
                    <MagnifyingGlassIcon className="pointer-events-none absolute left-8 top-3.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <input
                      type="search"
                      value={searchQuery}
                      onChange={handleSearch}
                      placeholder="Search profiles..."
                      className="h-12 w-full border border-gray-700 bg-transparent pl-11 pr-5 text-gray-400 focus:text-white placeholder:text-gray-400 focus:outline-none focus:bg-gray-700 rounded-md sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div> */}
        
            <div className="bg-gray-800 p-10">

              <div className="mx-auto max-w-7xl">
                <ul
                  role="list"
                  className="mx-auto pb-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
                >
                  {creatorProfiles.map(profile => {
                    const name = profile.frontmatter.name || profile.fields.slug;
                    return (
                      <li key={profile.fields.slug} className="flex flex-col items-start">
                        <Link to={profile.fields.slug} className="block w-full">
                          <img 
                            src={profile.frontmatter.profile_img || noImage} 
                            alt={`${name} Profile`}
                            className="aspect-[14/13] w-full rounded-xl object-cover bg-gray-800" 
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noImage;
                            }}
                          />
                          <div className="mt-6">
                            <h3 className="text-lg font-semibold tracking-tight text-white">{name}</h3>
                            <p className="text-base text-gray-300">{profile.frontmatter.status}</p>
                            <p className="text-sm text-gray-500">{profile.frontmatter.tagline}</p>
                          </div>
                          <div className="mt-4 text-gray-400 text-sm">
                            <p 
                              dangerouslySetInnerHTML={{
                                __html: profile.frontmatter.description || profile.excerpt,
                              }} 
                            />
                            <span className="text-gray-100"> read more</span>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              
            </div>


            <div className="flex items-center justify-between border-t border-gray-700 bg-gray-800 px-4 py-5 sm:px-5 sm:pb-5">
              <div className="inline-block w-full md:w-auto md:flex sm:flex-1 md:items-center md:justify-between">
                
                <div className="text-center pb-5 pt-3 md:p-0 md:text-left">
                  <p className="text-sm text-white">
                    Showing {Math.min((currentPage - 1) * profilesPerPage + 1, totalProfiles)} to {Math.min(currentPage * profilesPerPage, totalProfiles)} of {totalProfiles} results
                  </p>
                </div>
                

                <div className="">
                  <nav className="text-center md:isolate md:inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  {renderPagination()}
                  </nav>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>



    </Layout>
  );
};

export default CreatorListPage;

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      skip: $skip
      limit: $limit
      filter: { fileAbsolutePath: { regex: "/content/profile/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        excerpt(pruneLength: 165)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          name
          description
          profile_img
          tagline
          pronoun
          status
          professional_professions
          professional_projects
          professional_genres
          social_links
        }
      }
    }
  }
`;
